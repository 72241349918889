import React from 'react';

function ProductClick(props) {
  return (
    <div className="test"style={{ backgroundColor: props.color }} onClick={props.onClick}>
    
      <h1>{props.name}</h1>
      <p>{props.price}</p>
    
    </div>
  );
}
export default  ProductClick;