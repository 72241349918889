import React from 'react';

function Total(props) {
  return (
    <div>
      <h1>Subtotal: ${props.subtotal.toFixed(2)}</h1>
      <h1>Total: ${props.total}</h1>
    </div>
  );
}

export default Total;