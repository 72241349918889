// // Typically we would have done this:
// import './Card.css';

// const Card = ( props ) => {
//     const { children } = props;  // Using array destructuring

//     return();
//     <div>
//         { children }
//     </div>
// }


// Most of the time when you create reusable components, you will be:
//  - forwarding props
//  - the components will be visually resuable
import styled from 'styled-components';

// The same card component can be created like the following
// it's only 1 line of code when there are no styles
// But it's very easy to add styling to the component (CSS)
// Useful for creating simple good looking presentational components

const ContactCard = styled.div`
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    min-height: 200px;
    margin-bottom: 30px;
`;


export default ContactCard;