import styled from 'styled-components';


// Customizations you can do with styled components and props
// You can define props that will change the look of your component

const Button = styled.button`
    color: #fff;
    background-color: ${ (props) => {
        if (props.color === 'gold') {
            return '#D9A200';
        }
        if (props.color === 'darkred') {
            return '#a03232';
        }

        return '#666';
     } };
    border: 0;
    padding: 4px 8px;
    border-radius: 4px;
    width: 150px;
`;

export default Button;

        /*the color attribute "gold" and "darkred* will be accessed by the props object from other pages such as the home page.
        More specific, the use of props.color allows us to do this.

        The whole function contained with the background property allows the use of if/else statements which returns values indicated by the color attribute from other pages such as the home page page*/

