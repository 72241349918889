import React from 'react';

function PackageClick(props) {
  return (
    <div onClick={props.onClick}>
      <img src={props.imageUrl} alt={props.name} />
      <h2>{props.name}</h2>
      <p>{props.price}</p>
    </div>
  );
}
export default PackageClick;