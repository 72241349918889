import styled from 'styled-components';
import bgimage from '../assets/bgimage.jpg';

/*image (bgimage.jpg): Published on January 10, 2021,
Free to use under the Unsplash License
https://unsplash.com/photos/iXpuwXVuz8E */


// The same card component can be created like the following
// it's only 1 line of code when there are no styles
// But it's very easy to add styling to the component (CSS)
// Useful for creating simple good looking presentational components

// made use of the style-components module to include css styling

const BgImage = styled.div`
width:100%;
height:300px;
margin-top: -17px;
background: url(${bgimage});
background-repeat: no-repeat;
background-position: center;
background-size:cover;
background-attachment: fixed;
`;

/*styled.div (or any tag such as title tag <title> paragaraph tag <p>, h1 tag <h1> etc..) makes use of style components module allowing attach css properties without the use of a css file.*/


export default BgImage;

/*image: Published on January 10, 2021,
Free to use under the Unsplash License
https://unsplash.com/photos/iXpuwXVuz8E */