import React, { useState } from 'react';
import ProductCLick from '../components/PackageClick';
import Total from '../components/Total';
import Card2 from '../components/Card2';

function ProductList() {
  const [subtotal, setSubtotal] = useState(0);
  

  const handleClick = (price) => {
    setSubtotal(subtotal + price);
  };

  const total = (subtotal + (subtotal * 0.13)).toFixed(2);

  return (
      <div className="product">
            <div className="d-flex justify-content-center">

                                    <div className="row">
                          
                                            <div className="col mb-5"> 
                                            <Card2>
                                              <ProductCLick
                                                name="Manicure"
                                                price={19.99}
                                                imageUrl="./assets/manicure.jpg"
                                                onClick={() => handleClick(19.99)}
                                              />
                                              </Card2>
                                            </div>

                                            <div className="col mb-5"> 
                                            <Card2>
                                              <ProductCLick
                                                name="Massage"
                                                price={29.99}
                                                imageUrl="./assets/massage.jpg"
                                                onClick={() => handleClick(29.99)}
                                              />
                                              </Card2>
                                            </div>

                                            <div className="col mb-5"> 
                                            <Card2>
                                              <ProductCLick
                                                name="Pedicure"
                                                price={39.99}
                                                imageUrl="./assets/pedicure.jpg"
                                                onClick={() => handleClick(39.99)}
                                              />
                                            </Card2>
                                            </div>
                                            
{/*                                         
                                            <div className="col mb-5">  
                                            <Card2>
                                              <Product
                                                name="Essential Oil"
                                                price={49.99}
                                                imageUrl="./assets/essentail-oil.jpg"
                                                onClick={() => handleClick(49.99)}
                                              />
                                            </Card2>
                                            </div> */}
                                          </div>
       
            </div> 
            {/* d-flex justify-content-center end */}

                                    <div className="d-flex justify-content-center">
                                            <div className="row mb-5">
                                              <Total subtotal={subtotal} total={total} />
                                            </div>
                                    </div> 
                                    {/* row end   */}
    </div>
    //container end

  );
}


export default ProductList;