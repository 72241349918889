import BgImage from "../components/BgImage";
import Button from "../components/Button";
import { Link } from 'react-router-dom';


const Home = () => {
    return(
        
<container className="home-container">
      <section className="mb-3 pt-0">
        <div className="container-fluid px-0">
          <div className="row no-gutters">  
              <BgImage />  
          </div>
        </div>
      </section>

      <section className="section w-75 p-3 mx-auto ">
      <h1 className="hometitle">Welcome to Wellness Spa</h1>
      <p>Wellness Spa believes in helping you look and feel your best. For some that may include treating themselves to a Pedicure, Manicure or Massage. Also enjoy our great products which include Essential Oils, Footscrub, Face Mask and Hand Cream.</p>

      <p>Discover a new you. Come to our spa and leave feeling refreshed, relaxed and rejuvenated.<Link className="book-app-home nav-link" to="/bookappointment"> <strong>Book an appointment today!</strong></Link></p>
      </section>

      <section className="section w-25  mx-auto ">
            <div className="row mb-5">
                            <div className="col mb-3">                
                            <Link className="nav-link" to="/packages"><Button color="gold">Book a Massage </Button></Link>    
                                                      
                            </div>
                            <div className="col">                
                            <Link className="nav-link" to="/products"> <Button color="darkred">Buy our Products</Button></Link>                                    
                            </div>
            </div>         
      </section> 
      
</container>
  
    );
}

export default Home;

