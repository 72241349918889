import { Routes, Route } from 'react-router-dom';

// import Product from './components/Testers/BookStore';
import Header from './components/Header';


// Import our pages
import Home from './pages/Home';
// import BookStore from './pages/BookStore';
import Products from './pages/Product/Products';
import Packages from './pages/Packages';
import BookAppointment from './pages/BookAppointment';



import './App.css';


// The App component is the main scaffold for the entire react application
// This is normal where we would define most if not all the pages of the application.
// You can architect this however you want, React is flexible with how you should
// layout your project file directories.

// Let's define the basic structure / template of the app

// The Switch component looks for patterns in the URL and tries to match the render with the defined pattern
// Switch components will look for the first match, so the order of your Routes matters
// Or you could use the "exact" attribute. Each use-case is different, with their own advantages
// React router does partial matching
// If something partially matches another url then it'll return the first match. 
// example: /projects  and  /projects/demo1


function App() {
  return (
    <div className="App">
      <div className="mb-3">
        <Header />
      </div>
      <div className="mainContent container-fluid">
        {/* Routes is new in React Router Version 6 */}
        {/* <Switch> has been replaced with <Routes> */}
        <Routes>
          <Route exact path="/" element={<Home />} />
          {/* <Route exact path="/bookstore" element={<BookStore/>} />  */}
          <Route exact path="/packages" element={<Packages />} />
          <Route exact path="/products" element={<Products />} />
          <Route exact path="/bookappointment" element={<BookAppointment />} />
          
        </Routes>
      </div>
      {/* You could add a Footer or more content */}
      <div>

      </div>
      {/*>
      < BookStore />
       */}
    </div>
  );
}

export default App;


/*

DAY 9:

A. ROUTING:

Routing allows us to show different pages to the user.  
Traditionally, without the use of React, we would have multiple html pages and files.
In a React App, we have the ability to have a SPA (Single Page Application) with a multiple page feel. 
This allows us to provide the user with a normal web browsing experience by showing them different pages for different URLs while having only one html file. 
We use JavaScript to render different pages for different paths!
We have only one html file and re-render parts of the single page depending on which path the user has clicked on. 

React Router Dom is a router package that allows us to parse this path (the part after the domain) and then show us the appropriate JSX or component in our app.

This router package allows us to re-render/load the appropriate JSX or component code depending on the path the user would like to visit. 

Documentation:
https://reactrouter.com/web/guides/quick-start

A <Switch> looks through its children <Route>s and renders the first one that matches the current URL. 


ADD REACT ROUTER TO OUR PROJECT:
$ npm install styled-components

$ npm install react-router-dom

*/