import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
// Link tags will work as your anchor tag - defines which route path to link to

const Header = () => {

    return(
        <header>
            <nav className="navbar navbar-expand navbar-dark bg-dark">
                <div className="container-fluid">
                    <Link className="navbar-brand mb-2 py-0" to="/"><img src={ logo }  alt="logo"/></Link>
                    {/* font-size: .85em; */}
                    <div className="collapse navbar-collapse">
                        <div className="navbar-nav">
               
                            <Link className="nav-link" to="/">Home</Link>
                            <Link className="nav-link" to="/packages">Packages</Link>
                            <Link className="nav-link" to="/products">Products</Link>
                            <Link className="nav-link" to="/bookappointment">Book Appointment</Link>
                            
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default Header;

