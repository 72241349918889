import React, { useState } from 'react';
import ProductClick from '../../components/ProductClick';
import Total from '../../components/Total';
import './Products.css';
// import Card from '../../components/Card';

function ProductList() {
  const [subtotal, setSubtotal] = useState(0);
  

  const handleClick = (price) => {
    setSubtotal(subtotal + price);
  };

  const total = (subtotal + (subtotal * 0.13)).toFixed(2);

  return (
      <div className="product">
            <div className="d-flex justify-content-center">

                                    <div className="row">
                          
                                            <div className="col mb-5"> 
                                            {/* <Card color="blueviolet">  */}
                                              <ProductClick
                                                name="Essential Oils"
                                                price={20.99} 
                                                color="blueviolet"
                                                onClick={() => handleClick(20.99)}
                                              />
                                              {/* </Card> */}
                                            </div>

                                            <div className="col mb-5"> 
                                            {/* <Card color="coral">   */}
                                              <ProductClick
                                                name="Face Mask"
                                                color="coral"
                                                price={15.45}
                                                onClick={() => handleClick(15.45)}
                                              />
                                              {/* </Card> */}
                                            </div>

                                            <div className="col mb-5"> 
                                            {/* <Card color="crimson">  */}
                                              <ProductClick
                                                name="Foot Scrub"
                                                color="crimson"
                                                price={18.99}
                                                onClick={() => handleClick(18.99)}
                                              />
                                            {/* </Card> */}
                                            </div>
                                            
                                        
                                            <div className="col mb-5">  
                                            {/* <Card color="darkslateblue">   */}
                                              <ProductClick
                                                name="Hand Cream"
                                                color="darkslateblue"
                                                price={10.55}
                                                onClick={() => handleClick(10.55)}
                                              />
                                            {/* </Card> */}
                                            </div>
                                          </div>
       
            </div> 
            {/* d-flex justify-content-center end */}

                                    <div className=" d-flex justify-content-center">
                                            <div className="row mb-5">
                                              <Total subtotal={subtotal} total={total} />
                                            </div>
                                    </div> 
                                    {/* row end   */}
    </div>
    //container end

  );
}


export default ProductList;