import styled from 'styled-components';

const Card2 = styled.div`

    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    height: 250px;
    width:250px;
    display: inline-block;
    cursor: pointer;
    text-align: center;
    margin: 15px 0px 15px 0px !important;

    h1{
        font-size: 2em;
        color: #A03232;
    }

    p {
        font-size: 1.5em;
    }
`;

export default Card2;
