// Making the class component for really no reason.  Just for extra exposure
import React from 'react';
import styled from 'styled-components';

import Card from '../components/ContactCard';
import Button from '../components/Button';

const FormContainer = styled.div`
    width: 450px;
    margin: auto;
    margin-top: 50px;
`;

class BookAppointment extends React.Component {
    constructor() {
        super();
        this.state = {
            name: '',
            select: '',
            email: '',
            message: ''
        }
    }

    // Remember when you create methods in a class component make sure you use the arrow function to avoid having to bind it to "this"!!

    // For controlled components
    handleNameFieldChange = (event) => {
        // Update the state for the name field
        // console.log(event);
        // console.log(event.target.value);
        this.setState({
            name: event.target.value
        });
    }

    // For controlled components
    handleMessageFieldChange = (event) => {
        this.setState({
            message: event.target.value
        });
    }

    // Method for handling the form submission
    handleSubmit = () => {
       const { name, select, email, message } = this.state;
       window.alert(`
        Name: ${name},
        Type of Service: ${select},
        Email: ${email},
        Message: ${message}
       `);
    }

       // For controlled components
       handleSelectFieldChange = (event) => {
        this.setState({
            select: event.target.value
        });
    }

           // For controlled components
           handleEmailFieldChange = (event) => {
            this.setState({
                email: event.target.value
            });
        }
    


    render() {
        // JavaScript Logic would go here
        // To set up the controlled form fields, you have to force the values to reflect the form state.
        const { name, select, email, message } = this.state;

        return (
            // JSX goes in here
            <FormContainer>
                <Card>
                    <p className="lead">I would love to hear from you!</p>
                    <div className="form-group">
                    <label htmlFor="name">Name: </label>
                    {/* <h3 style={{color: "lightblue"}}><label htmlFor="name">Name: </label></h3> */}
                        <input
                            type="text"
                            id="name"
                            className="form-control"
                            value={ name }
                            onChange={ (event) => this.handleNameFieldChange(event) }
                        />
                    </div>
                    <div className="form-group">
                    <label htmlFor="select">Type of Service: </label>
                    <select value={ select } id="select" className="form-select" onChange={this.handleSelectFieldChange}>
                            <option value="manicure">None Select</option>
                            <option value="manicure">Manicure</option>
                            <option value="pedicure">Pedicure</option>
                            <option value="massage">Massage</option>
                            <option value="facial">Facial</option>
                    </select>
                       {/* onChange={this.handleSelectFieldChange} refers to the  handleSelectFieldChange function which updates the set state to the option that is selected.
                              this is an onChange listener*/}
					
                    </div>

                    <div className="form-group">
                    <label htmlFor="email">Email: </label>
                    {/* <h3 style={{color: "lightblue"}}><label htmlFor="name">Name: </label></h3> */}
                        <input
                            type="email"
                            id="email"
                            className="form-control"
                            value={ email }
                            onChange={ (event) => this.handleEmailFieldChange(event) }
                        />
                    </div>
					
                    <div className="form-group">
                        <label htmlFor="message">Message:</label>
                        <textarea
                            name="message"
                            id="message"
                            className="form-control"
                            rows="4"
                            value={message}
                            onChange={ (e) => this.handleMessageFieldChange(e) }
                        ></textarea>
                    </div>
                    <div className="form-group mt-3">
                    
                        <Button color="gold" onClick={this.handleSubmit} >Submit</Button>
                    </div>
                </Card>
            </FormContainer>
        );

    }

}

export default BookAppointment;